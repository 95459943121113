import { doc, addDoc, setDoc, getDoc, getDocs, deleteDoc, query, collection, orderBy, where, limit } from "firebase/firestore";
import { getDb } from "./db.mjs"

const profiles_collection_name = "shops"

export const findAllLinkedProfilesByCompanyId = async (companyId, profileIdList) => {
	//console.log(uid)
	//console.log(profileIdList)
    const res = []
	const querySnapshot = await getDocs(query(collection(getDb(), profiles_collection_name), where('companyId', '==', companyId), orderBy('sequence', 'desc')))
		
	querySnapshot.forEach((profile) => {
	  //console.log(`${profile.id} => ${profile.data()}`);
	  //console.log(profile.data())
	  if(profileIdList.indexOf(profile.id) !== -1){
		  res.push({
				id: profile.id, 
				...profile.data()
			})
	  }
	});
	
    return res
}