import MainNavbar from './navbar.js'
import Footer from './footer.js'

export default function Layout({ children }) {
	
  return (
    <>
      <div className="navbar-container">
		<MainNavbar />
	  </div>
	  <div className="layout-container pt-4 pb-4">
		  <main>{children}</main>
		  <Footer />
	  </div>
    </>
  )
}
