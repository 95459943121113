import './App.css';
import Layout from './components/layout.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import icBloompoint from './images/ic_bloompoint.jpg';

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { addBloomViewCountByBloomName, findBloomByBloomName } from './services/bloom.mjs'
import { findAllLinkedProfilesByCompanyId } from './services/profile.mjs'

function AppLayout() {
	const {paramBloomName} = useParams();
	
    const [bloomName, setBloomName] = useState('')
    const [bloomBio, setBloomBio] = useState('')
    const [profiles, setProfiles] = useState([])
	
    const fetchData = async () => {
		const bloomRes = await findBloomByBloomName(paramBloomName)

		const profilesRes = await findAllLinkedProfilesByCompanyId(bloomRes.companyId, bloomRes.profileIdList)
		
		await addBloomViewCountByBloomName(paramBloomName)

		setBloomName(paramBloomName)
		setBloomBio(bloomRes.bio)
		setProfiles([...profilesRes])
    }

    useEffect(() => {
		fetchData()
    })
	
  return (
	<div>
		<Helmet>
			<title>{"BloomTap - " + bloomName}</title>
			<meta name="description" content={bloomBio} />
			<meta name="keywords" content="Bloomtap" />
			<meta name="author" content="BloomTap" />
			<meta property="og:title" content={bloomName} />
			<meta property="og:description" content={bloomBio} />
			<meta property="og:image" content={profiles.length > 0  ? profiles[0].photoUrl : ''} />
			<meta property="og:url" content={"https://bloomtap.io/" + bloomName} />
			<meta name="twitter:title" content={bloomName} />
			<meta name="twitter:description" content={bloomBio} />
			<meta name="twitter:image" content={profiles.length > 0  ? profiles[0].photoUrl : ''} />
			
			<meta name="viewport" content="width=device-width, initial-scale=1"/>
		</Helmet>
		<div className="app">
			<Layout>
				<Outlet /> 
			</Layout>
		</div>
	</div>
  );
}

export default AppLayout;
