import { useParams } from 'react-router-dom';
import React from 'react'
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";

import imgDlAndroid from '../images/download_android_app.png'
import imgDlIOS from '../images/download_ios_app.png'
function ClaimStamp() {
    const {claimId} = useParams();

    /*const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(<QRCode value={claimId} />);*/
    return(
        <div className="claim-stamp-content">
            <h2>Congratulations!</h2>
            <p>You have earned BloomStamps.</p> 
            <p>Download the BloomTap App and Scan the following QR Code to claim your BloomStamps.</p>
            <div className="dl-app-container">
                <a href="#"><img className="img-dl-app" id="img-dl-ios" src={imgDlIOS} alt="Download BloomTap iOS App"/></a>
                <a href="#"><img className="img-dl-app" id="img-dl-android" src={imgDlAndroid} alt="Download BloomTap Android App"/></a>
            </div>
            <br/>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: "50%", width: "100%" }}>
                <QRCode
                  size={512}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={"https://bloomtap.io/claim-stamp/" + claimId}
                  viewBox={`0 0 512 512`}
                />
              </div>
        </div>
    )
}

export default ClaimStamp
