import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppLayout from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Redirect from "./components/redirect.js";
import Home from './pages/home.jsx';
import BloomTap from './pages/bloomtap.jsx';
import ClaimStamp from './pages/claim-stamp.jsx';
import Privacy from './pages/privacy.jsx';
import Tnc from './pages/tnc.jsx';
import FbDataDeletion from './pages/fb-data-deletion.jsx';
import GoogleDataDeletion from './pages/google-data-deletion.jsx';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <Routes> 
      <Route path="/" element={<Redirect url={ "https://shop.bloomtap.io" } />} />
      <Route element={<AppLayout />} >
        <Route path="/:paramBloomName" element={<Home />} />
        <Route path="/:paramBloomName/:profileId" element={<Home />} />
        <Route path="/bloomtap/:btid" element={<BloomTap />} />
        <Route path="/claim-stamp/:claimId" element={<ClaimStamp />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tnc" element={<Tnc />} />
        <Route path="/fb-data-deletion" element={<FbDataDeletion />} />
        <Route path="/google-data-deletion" element={<GoogleDataDeletion />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();